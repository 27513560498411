//
import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { getDesc, getValue } from '../_langWidgets';

// ----------------------------------------------------------------------

export const _attributeMapping = [
  {
    feId: 'HoverButtonProductURL',
    attribute: 'Hover Button Product URL'
  },
  {
    feId: 'ProductHeadBrandName',
    attribute: 'Brand Name'
  },
  {
    feId: 'ProductHeadProductName',
    attribute: 'Product Name'
  },
  {
    feId: 'ProductHeadProductTags',
    attribute: 'Product Tags'
  },
  {
    feId: 'ProductInfoDescription',
    attribute: 'Description'
  },
  {
    feId: 'ProductInfoBasicInformation',
    attribute: 'Basic Information'
  },
  {
    feId: 'ProductInfoNutrients',
    attribute: 'Nutrients'
  },
  {
    feId: 'ProductInfoCustomFields',
    attribute: 'Custom Fields'
  },
  {
    feId: 'ServingUnit',
    attribute: 'Serving Unit'
  },
  {
    feId: 'ReferenceQuantity',
    attribute: 'Reference Quantity'
  },
  {
    feId: 'ServingSize',
    attribute: 'Serving Size'
  },
  {
    feId: 'ProductInfoIngredients',
    attribute: 'Ingredients'
  },
  {
    feId: 'ProductInfoAllergens',
    attribute: 'Allergens'
  },
  {
    feId: 'ProductInfoNotesAndWarnings',
    attribute: 'Notes And Warnings'
  },
  {
    feId: 'ProductInfoResponsibleDistributor',
    attribute: 'Responsible Distributor'
  },
  {
    feId: 'ProductInfoStorageFields',
    attribute: 'Storage Fields'
  },
]


